import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NotFound } from '../app/routing/NotFound';
import {
  cartPath,
  catalogsPath,
  ordersPath,
  recommendationsPath,
  searchPartsFullPath,
  searchPartsPath,
  shoppinglistsPath,
} from '../pathDefinitions';
import CartRoutes from './cart/routes';
import CatalogRoutes from './catalog/routes';
import OrderRoutes from './orders/routes';
import RecommendationRoutes from './recommendation/routes';
import SearchRoutes from './search/routes';
import ShoppingListRoutes from './shoppinglists/routes';

export default () => (
  <Suspense fallback={<></>}>
    <Routes>
      <Route path="/" element={<Navigate to={searchPartsPath} replace />} />
      <Route
        path="parts/*" // Redirect legacy /ecom/parts/ to /ecom/search/
        element={<Navigate to={searchPartsFullPath} replace />}
      />
      <Route path={`${searchPartsPath}/*`} element={<SearchRoutes />} />
      <Route path={`${catalogsPath}/*`} element={<CatalogRoutes />} />
      <Route
        path={`${recommendationsPath}/*`}
        element={<RecommendationRoutes />}
      />
      <Route path={`${shoppinglistsPath}/*`} element={<ShoppingListRoutes />} />
      <Route path={`${ordersPath}/*`} element={<OrderRoutes />} />
      <Route path={`${cartPath}/*`} element={<CartRoutes />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Suspense>
);
